module services {
    export module master {
        export class skuService implements interfaces.master.ISKUService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDropdownList(ownerEntityId: number, searchText: string): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "SKU/GetDropdownList", {
                    ownerEntityId: ownerEntityId,
                    searchText: searchText
                });
            }

            getSkuByCode(): ng.resource.IResourceClass<interfaces.master.ISKU> {

                return this.$resource<interfaces.master.ISKU>(this.ENV.DSP_URL + "SKU/GetSkuByCode", {
                    code: "@code"
                }, {
                        query: {
                            method: 'GET',
                            isArray: false
                        }
                    });
            }

            getDefaultSku(): ng.resource.IResourceClass<interfaces.master.ISKU> {

                return this.$resource<interfaces.master.ISKU>(this.ENV.DSP_URL + "SKU/GetDefaultSku", {
                    proId: "@proId"
                }, {
                        query: {
                            method: 'GET',
                            isArray: false
                        }
                    });
            }

            getSKU(): ng.resource.IResourceClass<interfaces.master.ISKU> {

                return this.$resource<interfaces.master.ISKU>(this.ENV.DSP_URL + "SKU/GetSKU", {
                    skuId: "@skuId"
                }, {
                        query: {
                            method: 'GET',
                            isArray: false
                        }
                    });
            }
            getList(): ng.resource.IResourceClass<interfaces.master.IProductSKUPackagingDisplay> {
                return this.$resource<interfaces.master.IProductSKUPackagingDisplay>(this.ENV.DSP_URL + "SKU/GetList", {
                    proId: "@proId"
                }, {
                    query: {
                        method: 'GET',
                        isArray: true,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }                        
                    }
                });
            }
            getProductSKUPackaging(Id: number): ng.resource.IResourceClass<interfaces.master.IProductSKUPackagingMaster> {
                return this.$resource<interfaces.master.IProductSKUPackagingMaster>(this.ENV.DSP_URL + "SKU/GetProductSKUPackaging", {
                    Id: Id
                }, {
                    query: {
                        method: 'GET',
                        isArray: false,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }
                });
            }
            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SKU/Save");
            }
        }
    }
    angular.module("app").service("skuService", services.master.skuService);
}